<template>
<div class="items-grid-view w-full vx-row">
    <div class="vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
        <vx-card title="Buy Season Pass" class="grid-view-item mb-base overflow-hidden" @click="buySeasonPass" subtitle="Register Your Vehicle for Season Pass">
            <template slot="no-body">
                <div class="item-img-container bg-white h-64 flex items-center justify-center mb-4 cursor-pointer">
                    <img :src="CarAddImage" class="grid-view-img px-4" />
                </div>
                <div class="item-details px-4">
                    <div class="my-4">
                        <h6 class="truncate font-semibold text-center mb-1 hover:text-primary cursor-pointer">
                            Buy Season Pass
                        </h6>
                        <p class="item-description truncate text-sm">&nbsp;</p>
                    </div>
                </div>
            </template>
        </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4" v-for="(car, index) in vehicle" :key="index">
        <vx-card title="Vehicle" :customTitle="`${car.registrationPlate} (${car.registrationState})`" :subtitle="`${car.model}`" class="grid-view-item mb-base overflow-hidden">
            <template slot="no-body">
                <div class="item-img-container bg-white mt-base h-64 flex items-center justify-center mb-4 cursor-pointer">
                    <vue-qrcode :ref="`${car.id}qrcode`" :value="car.registrationPlate" :width="size" :level="level" :quality="quality"></vue-qrcode>
                </div>
                <div class="item-img-container bg-white flex items-center justify-center cursor-pointer">
                    <div class="centerx">
                        <vs-chip v-if="car.status === 'Processing'" color="warning">Processing</vs-chip>
                        <vs-chip v-if="car.status === 'Pending'" color="danger">Pending Approval</vs-chip>
                        <vs-chip v-if="car.status === 'Active'" color="success">Active</vs-chip>
                        <vs-chip v-if="car.status === 'Presale'" color="success">Presale</vs-chip>
                    </div>
                </div>
                <vs-divider class="mb-0 mt-5" />
                <div class="item-details px-4 text-white" :style="`background-color: ${car.statusColor}`">
                    <div class="m-0">
                        <p class="item-description truncate">
                            <b>{{car.passNumber}}:</b> {{ car.productName }}
                        </p>
                    </div>
                    <div class="m-0">
                        <p class="item-description truncate">
                            <b>Expires:</b> {{car.passExpiryDate | formatLongDate}}
                        </p>
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <div class="item-view-secondary-action-btn bg-light p-3 flex flex-grow items-center justify-center text-white cursor-pointer">
                        <vs-button class="width100" color="dark" @click="editCar(car.id)">Edit</vs-button>
                    </div>
                    <div v-if="car.expInMonth" class="item-view-secondary-action-btn bg-light p-3 flex flex-grow items-center justify-center text-white cursor-pointer">
                        <vs-button class="width100" color="success" @click="handleNewCar(car.id)">Renew</vs-button>
                    </div>
                    <!-- <div class="item-view-primary-action-btn p-3 flex flex-grow items-center bg-light justify-center text-white cursor-pointer">
                        <vs-button class="width100" color="danger" @click="deleteCar(car.id)">Delete</vs-button>
                    </div> -->
                </div>
            </template>
        </vx-card>
    </div>

</div>
</template>

<script>
import moment from "moment";
import VueQrcode from "vue-qrcode";
import {
    carData
} from "../../store/api/car";

export default {
    data() {
        return {
            vehicle: [],
            CarAddImage: require("@/assets/images/pages/car-add.png"),
            size: 256,
            value: "",
            level: "H",
            quality: 1
        };
    },
    components: {
        VueQrcode
    },
    props: {},
    computed: {},
    async created() {
        await this.loadVehicles();
    },
    methods: {
        async loadVehicles() {
            this.vehicle = await carData.GetCarByUser();
            if (this.vehicle.length > 0) {
                var i;
                var j = this.vehicle.length;
                for (i = 0; i < j; i++) {
                    const expDate = this.vehicle[i].passExpiryDate;
                    const today = moment().add(30, "days");
                    let compare = moment(expDate).isSameOrAfter(today);
                    if (!compare) {
                        this.vehicle[i] = {
                            ...this.vehicle[i],
                            expInMonth: true
                        };
                    }
                }
            }
        },
        buySeasonPass() {
            this.$router.push(`/Vehicles/addCar`);
        },
        editCar(id) {
            this.$router.push(`/Vehicles/edit/${id}`);
        },
        handleNewCar(id) {
            this.$router.push(`/Vehicles/reNew/${id}`);
        },
        async deleteCar(id) {
            if (confirm("are you sure ? ")) {}
        },
        handleDownload(linkToDownload, name) {
            const a = document.createElement("a");
            a.href = this.$refs[linkToDownload + "qrcode"][0].dataUrl;
            a.download = `${name}-pass.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
};
</script>
